
import { createTheme } from '@mui/material/styles';

export default createTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#9b59b6',
        },
        secondary: {
            main: '#282a36',
        },
        background: {
            paper: '#282a36',
            default: '#44475a',
        },
        text: {
            primary: '#f8f8f8',
            disabled: "#CCC",
        },
        icon: {
            '& path': {
                fill: '#eee'
            },
        },
    },
    shadows: Array(25).fill('none'),
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    overrides: {
        MuiAppBar: {
            root: {
                backgroundColor: "#ff79c6"
            }
        }
    }
});