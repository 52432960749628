import * as React from 'react'
import { Lightbox } from "react-modal-image";

function Thumbnail({smallImg, largeImg, title}){
    const [showModal, setShowModal] = React.useState(false);

    return <div>
            <img className="movie-thumbnail" onClick={() => setShowModal(!showModal)} src={smallImg} alt="No Image"/>
            {showModal && (
                <Lightbox
                    small={largeImg}
                    medium={largeImg}
                    large={largeImg}
                    hideDownload
                    hideZoom
                    alt={title}
                    onClose={() => setShowModal(false)}
                />
            )}
      </div>
}

export default Thumbnail;