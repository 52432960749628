import './App.css';
import NavBar from './components/NavBar';
import NewSteels from './components/tables/NewSteels';
import theme from './theme'
import { ThemeProvider } from '@mui/material/styles';
let name = "Steel Cas.es"


function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <NavBar name={name} />
        <NewSteels />
      </div>
    </ThemeProvider>
  );
}

export default App;
