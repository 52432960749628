import React from "react";
import MUIDataTable from "mui-datatables";
import { CircularProgress, Typography } from '@mui/material';

function Table(props){

    let title = <div style={{textAlign: "left"}}>
        <Typography variant="h6">
            {props.title}
        </Typography>
        {props.isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
    </div>

    return (
        <MUIDataTable
            // style={commonProps}
            // icons={tableIcons}
            columns={props.columns}
            data={props.data}
            title={title}
            options={{
                download: false,
                print: false,
                selectableRows: "none",
                rowsPerPageOptions: [],
                responsive: 'standard',
                rowsPerPage: 25,
                ...props.options
            }}
            components={props.components}
        />
    );
}

export default Table