import AddAlertIcon from '@mui/icons-material/AddAlert';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';

import InventoryIcon from '@mui/icons-material/Inventory';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { Tooltip } from '@mui/material';

function ActionGroup(props) {

    return <div>
        {!props.notify.enabled &&
            <Tooltip title="Turn on Notifications" arrow>
                <IconButton aria-label="add" onClick={() => props.notify.callback(true)}>
                    <AddAlertIcon />
                </IconButton>
            </Tooltip>
        }
        {props.notify.enabled &&
            <Tooltip title="Turn off Notifications" arrow>
                <IconButton aria-label="remove" onClick={() => props.notify.callback(false)}>
                    <NotificationsIcon color='primary' />
                </IconButton>
            </Tooltip>
        }
        {!props.inventory.enabled &&
        <Tooltip title="Add to Inventory" arrow>
            <IconButton aria-label="Add to Inventory" onClick={() => props.inventory.callback(true)}>
                <Inventory2OutlinedIcon />
            </IconButton>
        </Tooltip>
        }
        {props.inventory.enabled &&
        <Tooltip title="Remove from Inventory" arrow>
            <IconButton aria-label="Remove from Inventory" onClick={() => props.inventory.callback(false)}>
                <InventoryIcon color='primary' />
            </IconButton>
        </Tooltip>
        }
    </div>
}

export default ActionGroup;