function Available(props) {
    if (props.isAvailable) {
        return <a target="_blank" rel="noopener noreferrer" href={props.link}>
            <div className="add-button available">
                Buy Now
            </div>
        </a>
    } else {
        return <a target="_blank" rel="noopener noreferrer" href={props.link}>
            <div className="add-button unavailable">
                Sold Out
            </div>
        </a>
    }
}

export default Available;