import * as React from 'react'
import Table from "./Table";
import Available from '../Available'
import Formats from '../Formats'
import ActionGroup from '../ActionGroup';
import axios from 'axios';
import Thumbnail from '../Thumbnail';
import Loader from 'react-spinners/BeatLoader'
import { debounceSearchRender } from "./Debounce";

function NewSteels(props) {

    let mobileStyle = {
        borderBottom: "1px solid rgba(224, 224, 224, 1)"
    }
    let desktopStyle = {
        whiteSpace: "nowrap",
        position: "sticky",
        left: "0",
        zIndex: 100,
        borderBottom: "1px solid rgba(224, 224, 224, 1)"
    }
    let desktopHeaderStyle = {
        whiteSpace: "nowrap",
        position: "sticky",
        left: "0",
        zIndex: 101,
        borderBottom: "1px solid rgba(224, 224, 224, 1)"
    }

    const [data, setData] = React.useState(null)
    const [width, setWidth] = React.useState(window.innerWidth);
    const [isLoading, setIsLoading] = React.useState(true)
    const breakpoint = 620;

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth)
        window.addEventListener("resize", handleWindowResize);

        // Return a function from the effect that removes the event listener
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    const [notify, setNotify] = React.useState(false)
    const [inventory, setInventory] = React.useState(false)
    
    const [page, setPage] = React.useState(0)
    const [sortOrder, setSortOrder] = React.useState({})
    const [searchText, setSearchText] = React.useState(null)

    React.useEffect(() => {
        if (data){
            onUpdate(page, sortOrder, [], searchText)
        }
    }, [searchText])

    let style = width > breakpoint ? desktopStyle : mobileStyle
    let header = width > breakpoint ? desktopHeaderStyle : mobileStyle
    let columns = [
        {
            label: "Name",
            name: "name",
            options: {
                setCellProps: () => ({
                    style: style
                }),
                setCellHeaderProps: () => ({
                    style: header
                })
            }

        }, {
            label: "Availability",
            name: "available",
            options: {
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return <Available isAvailable={data.Items[dataIndex].available} link={data.Items[dataIndex].url} />
                }
            }
        }, {
            label: "Image",
            name: "image",
            options: {
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const item = data.Items[dataIndex]
                    return <Thumbnail smallImg={item.images.image} largeImg={item.images.largeImage} title={item.name} />
                }
            }
        }, {
            label: "Price",
            name: "price",
            options: {
                sort: false,
                display: false
            }
        }, {
            label: "Retailer",
            name: "retailer",
            options: {
                sort: false
            }
        }, {
            label: "Listed",
            name: "listingDate",
        }, {
            label: "Year",
            name: "filmReleaseDate",
            options: {
                sort: false
            }
        }]

    React.useEffect(() => {
        setIsLoading(true)
        axios({
            method: 'get',
            url: 'https://api.steelcas.es/steelbook/list'
        }).then(function (response) {
            setPage(0)
            setData(response.data)
            setIsLoading(false)
        })
    }, []);

    function onUpdate(newPage, sortOrder, filters, searchText) {
        setIsLoading(true)
        axios({
            method: 'post',
            url: 'https://api.steelcas.es/steelbook/list',
            data: {
                page: newPage,
                search: searchText ?? "",
                sort: sortOrder
            }
        }).then(function (response) {
            setData(response.data)
            setIsLoading(false)
        })
    }
    let options = {
        count: data ? data.Count : 0,
        serverSide: true,
        page: page,
        sortOrder: data ? data.Sort : {},
        filter: false,
        searchText: searchText,
        viewColumns: false,
        customSearchRender: debounceSearchRender(1000),
        onTableChange: (action, tableState) => {
            if (action === "propsUpdate" || action === "onSearchOpen")
                return
            if (action === "search") {
                setSearchText(tableState.searchText)
                return
            }
            if (action === "onSearchClose") {
                setSearchText(null)
            }
            let filters = []
            for (let i = 0; i < tableState.filterList.length; i++) {
                if (tableState.filterList[i].length > 0) {
                    filters.push({
                        column: tableState.columns[i],
                        filters: tableState.filterList[i],
                    })
                }
            }

            setPage(tableState.page)
            onUpdate(tableState.page, tableState.sortOrder, filters, tableState.searchText)
        }
    }

    if (isLoading) {
        return <div style={{ marginTop: "100px" }}>
            <Loader color="#9b59b6" size="15px" />
        </div>

    } else {
        return <Table isLoading={false} columns={columns} data={data.Items} options={options} />
    }
}

export default NewSteels;